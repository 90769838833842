<template>
  <div class="app-container">
    <!-- 搜索栏开始 -->
    <el-form :inline="true" :model="formInline" class="demo-form-inline">
      <el-form-item label="账单日期">
        <el-date-picker v-model="formInline.time" type="datetime" placeholder="选择日期时间" value-format="yyyyMMdd"></el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" plain round @click="searchData" icon="el-icon-search">查询</el-button>
      </el-form-item>
    </el-form>
    <!-- 搜索栏结束 -->
    <!-- 表格开始 -->
    <el-table
      border
      :data="tableDataList"
      style="width: 100%;"
      height="700"
      :row-class-name="tableRowClassName"
    >
      <el-table-column label="序号" width="100">
        <template slot-scope="scope">
          <span style="margin-left: 10px">{{ scope.row.index }}</span>
        </template>
      </el-table-column>
      <el-table-column label="记录编号">
        <template slot-scope="scope">
          <span style="margin-left: 10px">{{ scope.row.id }}</span>
        </template>
      </el-table-column>
      <el-table-column label="账单日期">
        <template slot-scope="scope">
          <span style="margin-left: 10px">{{ scope.row.billDay }}</span>
        </template>
      </el-table-column>
      <el-table-column label="对账日期">
        <template slot-scope="scope">
          <span style="margin-left: 10px">{{ scope.row.billTime }}</span>
        </template>
      </el-table-column>
      <el-table-column label="日交易总金额[平台侧]">
        <template slot-scope="scope">
          <span style="margin-left: 10px">{{ scope.row.chargeMoneyIst }}</span>
        </template>
      </el-table-column>
      <el-table-column label="日交易总金额[云纵侧]">
        <template slot-scope="scope">
          <span style="margin-left: 10px">{{ scope.row.chargeMoneyYz }}</span>
        </template>
      </el-table-column>
      <el-table-column label="平账金额">
        <template slot-scope="scope">
          <span style="margin-left: 10px">{{ scope.row.chargeMoneyCorrect }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作类型">
        <template slot-scope="scope">
          <span style="margin-left: 10px">{{ scope.row.operatorType }}</span>
        </template>
      </el-table-column>
        <el-table-column label="账单">
        <template slot-scope="scope">
          <el-button size="mini" type="primary" plain @click="getData(scope.row)">账单下载</el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 表格结束 -->
    <!-- 分页 -->
    <div style="text-align:right;">
      <Paging :totalSize="totalSize" :getMethods="getTableData" />
    </div>
  </div>
</template>

<script>
import Paging from "../../../components/Paging/Paging.vue";
export default {
  components:{
    Paging
  },
  data() {
    return {
      formInline: {
        time: null,
      },
      tableDataList: [],
      totalSize:0,
    };
  },
  methods: {
     //获取表格数据
    getTableData(pageData) {
      this.pageDatalist = { ...pageData };
      const {time} =this.formInline;
      let fixedData={
        billDay:this.time!==null?time:'',
      };
      let datalists = {
        pageConfig: {
          orderType: "desc", //升序、降序
          pageSize: this.pageDatalist.pageSize, //每页大小
          orderField: "id", //排序字段
          pageNum: this.pageDatalist.pageNum //第n页
        },
        baseBean: fixedData
      };
      this.$axios.post("/yzBill/queryBillLog", datalists).then(res => {
        this.loading = false;
        if (res.data.state == 1) {
          this.tableDataList = res.data.billLogPage;
          let index =(this.pageDatalist.pageNum - 1) * this.pageDatalist.pageSize; //分页时编号计算
          this.tableDataList.map(item => {
            index++;
            item.index = index;
            if (item.billTime !== undefined) {
              item.billTime = this.getTime(item.billTime);
            }
            item.operatorType=item.operatorType==1?'自动':'人工'
            return item;
          });
          this.totalSize = res.data.count;
        }
      });
    },
    // 查询
    searchData() {
      this.getTableData(this.pageDatalist);
    },
    // 账单下载
    getData(row){
      let billDays=row.billDay;
      billDays=billDays.split('');
      billDays.splice(4,0,'-');
      billDays.splice(7,0,'-');
      billDays=billDays.join('');
      let timeStemp=new Date(billDays);
      timeStemp=timeStemp.getTime()
       this.$axios.get('/yzBill/getYzDayBillFileDownUrl',{params:{timeStemp}})
       .then(res=>{
         if(res.data.state==1){   
           window.open(res.data.yzUrl); 
          this.getExcel(res.data.istUrl);
           this.$message({message:res.data.msg,type: "success"});
         }else{
           this.$message({message:res.data.msg,type: "error"});
         }
       })
    },
    // 导出表格
    getExcel(url){//后端返回url
      let link = document.createElement('a')
      link.style.display = 'none'
      link.href = url;
      link.setAttribute('id', 'downloadLink')
      link.setAttribute('download', '11.xls')
      document.body.appendChild(link)
      link.click()
      // 删除添加的a链接
      let objLink = document.getElementById('downloadLink')
      document.body.removeChild(objLink)
    },
    // 时间转换
    getTime(params) {
      let unixTimestamp = new Date(params * 1000); //时间戳转换成日期
      // 定义转换返回方式
      Date.prototype.toLocaleString = function() {
        return (
          this.getFullYear() +
          "-" +
          (this.getMonth() + 1) +
          "-" +
          +this.getDate() +
          "" +
          " " +
          this.getHours() +
          ":" +
          this.getMinutes() +
          ":" +
          this.getSeconds()
        );
      };
      params = unixTimestamp.toLocaleString();
      return params;
    },
    // 表格颜色
    tableRowClassName({ row, rowIndex }) {
      if (rowIndex % 2 === 1) {
        return "success-row";
      }
      return "";
    }
  }
};
</script>

<style lang="scss" scoped>
</style>